<template>
  <div>
    <div class="main-title">{{ $t("stakingTitle") }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <div class="page" id="fix">
      <el-row type="flex" justify="space-around" style="margin-bottom: 82px">
        <el-col>
          <div :class="['step', activeStep === 'first' ? 'active-step' : '']">
            {{ $t('step1') }}
          </div>
          <div :class="['tab', activeStep === 'first' ? 'active' : '']"></div>
        </el-col>
        <el-col>
          <div :class="['step', activeStep === 'second' ? 'active-step' : '']">
            {{ $t('step2') }}
          </div>
          <div :class="['tab', activeStep === 'second' ? 'active' : '']"></div>
        </el-col>
      </el-row>
      <template v-if="activeStep === 'first'">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item :label="$t('stakingCurrencyType')" required>
            <el-select
              v-model="usdtType"
              :placeholder="$t('stakingType')"
              size="large"
            >
              <el-option
                v-for="element in  ['USDT', 'BUSD']"
                :label="element"
                :value="element"
                :key="element"
              ></el-option>
            </el-select>
          </el-form-item>
          <div v-if="usdtType === 'BUSD'" style="border: 1px #DCDFE6 solid; padding: 10px; margin-bottom: 15px; border-radius: 6px">
            <!--          <div style="white-space: pre-line; color: #606060">{{$t('busdDescriptionIn')}}</div>-->
            <div style="color: #606060; margin-bottom: 10px">{{$t('stakingDescLine1')}}</div>
            <div style="color: #606060; margin-bottom: 10px">{{$t('stakingDescLine2')}}</div>
            <div style="color: #606060">{{$t('stakingDescLine3')}}</div>
            <div style="color: #606060">{{$t('stakingDescLine4')}}</div>
            <div style="color: #606060">{{$t('stakingDescLine5')}}</div>
            <div style="color: #606060; margin-top: 10px">{{$t('stakingDescLine6')}}</div>
          </div>
          <div v-if="usdtType === 'BUSD'" style="display: flex; align-items: center; margin-bottom: 15px">
            <InputComponent
              placeholder="BUSD wallet for transfer"
              readonly
              v-model="walletToCopy"
            />
            <ButtonComponent
              style="margin-left: 20px"
              :content="$t('btnCopy')"
              fill
              @click="walletToCopy"
              icon
            />
          </div>
          <el-form-item :label="$t('exchangeEmail')" prop="email" required>
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('exchangePassword')"
            prop="password"
            required
            class="pass-input"
          >
            <el-input v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item :label="$t('chooseStakingType')" prop="type" required>
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('stakingType')"
              size="large"
              @change="changeSelector"
            >
              <el-option
                v-for="element in stakingTypes || []"
                :label="$t(element.key)"
                :value="element.key"
                :key="element.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('stakingDays')" prop="days" required v-if="ruleForm.type === 'FIXED'">
<!--            <el-input-number v-model="ruleForm.days" :min="10" :max="365"></el-input-number>-->
            <el-select
              v-model="ruleForm.days"
              :placeholder="$t('stakingType')"
              size="large"
              @change="changeSelectorDays"
            >
              <el-option
                v-for="element in stakingTypes.find(item => item.key === 'FIXED').values || []"
                :label="$t(element.key)"
                :value="element.value"
                :key="element.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="usdtType === 'USDT'" :label="$t('exchangeWallet')" prop="wallet" required>
            <el-input v-model="ruleForm.wallet"></el-input>
          </el-form-item>
          <el-form-item v-else :label="$t('exchangeTransaction')" prop="transaction" required>
            <el-input v-model="ruleForm.transaction"></el-input>
          </el-form-item>
          <el-form-item prop="terms" required>
            <el-checkbox v-model="ruleForm.terms" name="terms">{{
              $t("termsConditions")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <ButtonComponent :content="$t('btnDiscard')" @click="discard" />
            <ButtonComponent
              :content="$t('btnNext')"
              fill
              style="margin-left: 10px"
              icon
              @click="() => this.request = true"
            />
          </el-form-item>
        </el-form>
      </template>
      <template v-if="activeStep === 'second'">
        <div class="row-copy">
          <InputComponent
            placeholder="USDT wallet for transfer"
            readonly
            v-model="activationWallet"
          />
          <ButtonComponent
            style="margin-left: 20px"
            :content="$t('btnCopy')"
            fill
            @click="copy"
            icon
          />
        </div>
        <div class="how-it-works" style="margin-top: 52px">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>
          <div class="instructions">
            <span>{{ $t("stakingLine1") }}</span>
            <span>{{ $t("stakingLine2") }}</span>
            <span>{{ $t("stakingLine3") }}</span>
            <span>{{ $t("stakingLine4") }}</span>
            <span>{{ $t("stakingLine4") }}</span>
            <span>{{ $t("stakingMin") }} <span>$ {{min}}</span></span>
            <span>{{ $t("stakingMax") }} <span>$ {{max}}</span></span>
          </div>
          <span class="readme">{{ $t("faqReadme") }}</span>
          <div class="readme-block">
            <span>{{ $t("stakingReadmeLine1") }}</span>
            <span>{{ $t("stakingReadmeLine2") }}</span>
            <span>{{ $t("stakingReadmeLine3") }}</span>
          </div>
          <div style="margin-bottom: 20px;margin-top: 20px">
            <el-checkbox
              v-model="readAndUnderstand"
              :label="$t('activationCheckBox')"
            >
            </el-checkbox>
          </div>
          <ButtonComponent
            :content="$t('btnReadUnderstand')"
            @click="goHome"
            fill
            icon
          />
        </div>
      </template>
    </div>
    <el-dialog :visible.sync="dialog">
      <el-row class="activation-dialog">
        <span class="pop-title">{{ $t("activationPopap") }}</span>
      </el-row>
      <div class="pop-wallet">
        <InputComponent
          placeholder="USDT wallet for transfer"
          v-model="activationWallet"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnCopyClose')"
          fill
          icon
          @click="copyAndHome"
        />
      </div>
    </el-dialog>
    <el-dialog :visible.sync="request" class="request-info">
      <el-row >
        <span class="pop-title">{{ $t("attention") }}</span>
      </el-row>
      <div class="request-description">
        <span>{{ $t("stakingInfo", { currency: usdtType }) }}</span>
        <span>{{ $t("stakingSupport") }}</span>
      </div>
      <div class="pop-request">
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnCancel')"
          fill
          @click="() => this.request = false"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnContinue')"
          fill
          icon
          @click="confirmDialog"
        />
      </div>
    </el-dialog>
    <el-dialog :visible.sync="stakingError" class="request-info">
      <el-row >
        <span class="pop-title">{{ $t("stakingTitle") }}</span>
      </el-row>
      <div class="request-description">
        <span>{{ $t("stakingError2") }}</span>
        <span>{{ $t("stakingError3") }}</span>
        <span>{{ $t("stakingMinInfo") }} ${{min}}</span>
        <span>{{ $t("stakingMaxInfo") }} ${{max}}</span>

      </div>
      <div class="pop-button">
        <InputComponent
          placeholder="USDT wallet for transfer"
          v-model="activationWallet"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnCopyClose')"
          fill
          icon
          @click="copyAndHome"
        />
      </div>
    </el-dialog>
    <el-dialog :visible.sync="busdConfirm" class="request-info">
      <el-row >
        <span class="pop-title">{{ $t("successfully") }}</span>
      </el-row>
      <div class="request-description">
        <span>{{ $t("busdStakingConfirm") }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent";
import InputComponent from "@/components/InputComponent";
import { mapActions, mapState } from "vuex";
export default {
  name: "Staking",
  components: { InputComponent, ButtonComponent },
  data() {
    return {
      checked: false,
      activeStep: "first",
      secondStepDisable: false,
      dialog: false,
      request: false,
      stakingError: false,
      activationWallet: null,
      min: null,
      max: null,
      readAndUnderstand: null,
      confirmed: false,
      usdtType: "USDT",
      walletToCopy: "",
      busdConfirm: false,
      ruleForm: {
        email: "",
        password: "",
        type: "",
        wallet: "",
        days: undefined,
        transaction: "",
        terms: null
      },
      rules: {
        email: [
          { required: true, message: "Please input email", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapState(["stakingTypes"])
  },
  methods: {
    ...mapActions(["getStakingTypes", "getBusdWallet", "sendStakingBusd", "sendStaking"]),
    submitForm(formName) {
      this.request = false;
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (this.usdtType === 'USDT') {
              const data = {
                username: this.ruleForm.email,
                password: this.ruleForm.password,
                staking_type: this.ruleForm.type,
                usdt_wallet: this.ruleForm.wallet,
                days: this.ruleForm.days,
              };
              this.sendStaking(data)
                .then(res => {
                  this.activationWallet = res.wallet;
                  this.min = res.min;
                  this.max = res.max;
                  this.activeStep = "second";
                })
                .catch(err => {
                  console.log('staking err',err, err.data);
                  if (err.data.code === 'HAS_ACTIVE_STAKING_REQUEST') {
                    this.activationWallet = err.data.payload.wallet
                    this.min = err.data.payload.min;
                    this.max = err.data.payload.max;
                    this.stakingError = true;
                    this.activationWallet = err.data.payload.wallet
                  } else {
                    this.$notify.error({
                      title: this.$t("ERROR"),
                      message: this.$t(err.data.code)
                    });
                  }
                });
            } else {
              const data = {
                username: this.ruleForm.email,
                password: this.ruleForm.password,
                staking_type: this.ruleForm.type,
                tx_id: this.ruleForm.transaction,
                days: this.ruleForm.days,
              };
              this.sendStakingBusd(data)
                .then(() => {
                  this.resetForm(formName)
                  this.busdConfirm = true
                })
                .catch(err => {
                  console.log('staking err',err, err.data);
                  if (err.data.code === 'HAS_ACTIVE_STAKING_REQUEST') {
                    this.activationWallet = err.data.payload.wallet
                    this.min = err.data.payload.min;
                    this.max = err.data.payload.max;
                    this.stakingError = true;
                    this.activationWallet = err.data.payload.wallet
                  } else {
                    this.$notify.error({
                      title: this.$t("ERROR"),
                      message: this.$t(err.data.code)
                    });
                  }
                });
            }



          } else {
            // console.log("error");
            this.$notify.error({
              title: "Validation fields error"
            });
            return false;
          }
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeSelector(item) {
      this.ruleForm.days = item === 'FIXED' ?
        this.stakingTypes.find(item => item.key === 'FIXED').values.find(element => element.default === true).value :
        undefined;
    },
    changeSelectorDays(item) {
      console.log(item)
      console.log(this.ruleForm.days)
    },
    discard() {
      this.resetForm("ruleForm");
      this.$router.back();
    },
    confirmDialog() {
       this.submitForm('ruleForm');
    },
    goHome() {
      if (this.readAndUnderstand) {
        this.activeStep = "first";
        // this.$router.push("/");
        window.location = "https://fridn.com";
      } else {
        this.dialog = true;
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.activationWallet);
    },
    copyAndHome() {
      this.copy();
      this.stakingError = false
      this.$router.push("/");
    }
  },
  async copyBusd() {
    await navigator.clipboard.writeText(this.walletToCopy);
  },
  walletCopy() {
    this.copyBusd()
    //this.howItWorkCopy = true;
  },
  created() {
    this.getBusdWallet()
      .then(res => {
        this.walletToCopy = res.wallet

      })
    this.getStakingTypes()
      .then(() => {
        const result = this.stakingTypes.find(
          item => item.default === true
        ).key;
        this.ruleForm.type = result
        this.ruleForm.days = result === 'FIXED' ?
          this.stakingTypes.find(item => item.key === 'FIXED').values.find(element => element.default === true).value :
          undefined;
      })
      .catch(err => {
      // console.log(err)
      this.$notify.error({
        title: err.status,
        message: err.statusText
      });
    });
  }
};
</script>

<style>
.tab {
  height: 1px;
  background-color: #cfd2d3;
}
.active {
  background-color: #3f5193;
  border-radius: 200px 200px 0px 0px;
  height: 5px;
  border: none;
}
.home {
  margin-bottom: 234px;
}

.chevron {
  margin-bottom: 80px;
}

.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.request-info .request-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
}
.instructions {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions span:before {
  content: url("../assets/button_submit_arrow.svg");
  margin-right: 10px;
}
.instructions span {
  text-align: start;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-left: 24px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.readme-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  margin-left: 24px;
}
.step {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: rgba(37, 37, 66, 0.5);
  margin-bottom: 30px;
}
.active-step {
  color: #252542;
  margin-bottom: 26px;
}
.row-copy {
  display: flex;
  align-items: center;
  margin-top: 104px;
}

</style>
