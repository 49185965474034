<template>
  <div>
    <div class="main-title">{{ $t("accountActivation") }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <div class="page" id="fix">
      <el-row type="flex" justify="space-around" style="margin-bottom: 82px">
        <el-col>
          <div :class="['step', activeStep === 'first' ? 'active-step' : '']">
            {{ $t('step1') }}
          </div>
          <div :class="['tab', activeStep === 'first' ? 'active' : '']"></div>
        </el-col>
        <el-col>
          <div :class="['step', activeStep === 'second' ? 'active-step' : '']">
            {{ $t('step2') }}
          </div>
          <div :class="['tab', activeStep === 'second' ? 'active' : '']"></div>
        </el-col>
      </el-row>
      <template v-if="activeStep === 'first'">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item :label="$t('exchangeEmail')" prop="email" required>
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('exchangePassword')"
            prop="password"
            required
            class="pass-input"
          >
            <el-input v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item :label="$t('exchangeType')" prop="type" required>
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('exchangeType')"
              size="large"
              @change="changeSelector"
            >
              <el-option
                v-for="element in activationTypes || []"
                :label="$t(element.name)"
                :value="element.title"
                :key="element.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('usdtAmountFees')" prop="amount">
            <el-input
              class="wallet-amount"
              readonly
              v-model="ruleForm.amount"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('exchangeWallet')" prop="wallet" required v-if="!ruleForm.hideWallet">
            <el-input v-model="ruleForm.wallet"></el-input>
          </el-form-item>
          <el-form-item :label="$t('referralLink')" prop="referral">
            <el-input v-model="ruleForm.referral"></el-input>
          </el-form-item>
          <el-form-item prop="terms" required>
            <el-checkbox v-model="ruleForm.terms" name="terms">{{
              $t("termsConditions")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <ButtonComponent :content="$t('btnDiscard')" @click="discard" />
            <ButtonComponent
              :content="$t('btnActivate')"
              fill
              style="margin-left: 10px"
              icon
              @click="submitForm('ruleForm')"
            />
          </el-form-item>
        </el-form>
      </template>
      <template v-if="activeStep === 'second'">
        <div v-if="ruleForm.type !== 'Basic'">
        <div class="row-copy">
          <InputComponent
            placeholder="USDT wallet for transfer"
            readonly
            v-model="activationWallet"
          />
          <ButtonComponent
            style="margin-left: 20px"
            :content="$t('btnCopy')"
            fill
            @click="copy"
            icon
          />
        </div>
        <div class="how-it-works" style="margin-top: 52px">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>
          <div class="instructions">
            <span>{{ $t("activationLine1") }}</span>
            <span>{{ $t("activationLine2") }}</span>
          </div>

          <div style="margin-bottom: 20px;margin-top: 20px">
            <el-checkbox
              v-model="readAndUnderstand"
              :label="$t('activationCheckBox')"
            >
            </el-checkbox>
          </div>
          <ButtonComponent
            :content="$t('btnReadUnderstand')"
            @click="goHome"
            fill
            icon
          />
        </div>
        </div>
        <div v-else>
          <div class="activation-complete">
            {{ $t('activated') }}
          </div>
          <ButtonComponent
            :content="$t('btnGoHome')"
            @click="goSite"
            fill
            icon
          />
        </div>
      </template>
    </div>
    <el-dialog :visible.sync="dialog">
      <el-row class="activation-dialog">
        <span class="pop-title">{{ $t("activationPopap") }}</span>
      </el-row>
      <div class="pop-wallet">
        <InputComponent
          placeholder="USDT wallet for transfer"
          v-model="activationWallet"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnCopyClose')"
          fill
          icon
          @click="copyAndHome"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent";
import InputComponent from "@/components/InputComponent";
import { mapActions, mapState } from "vuex";
export default {
  name: "Activation",
  components: { InputComponent, ButtonComponent },
  data() {
    return {
      checked: false,
      activeStep: "first",
      secondStepDisable: false,
      dialog: false,
      activationWallet: null,
      readAndUnderstand: null,
      ruleForm: {
        email: "",
        password: "",
        type: "",
        amount: "",
        hideWallet: false,
        wallet: undefined,
        referral: undefined,
        terms: null
      },
      rules: {
        email: [
          { required: true, message: "Please input email", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapState(["activationTypes"])
  },
  methods: {
    ...mapActions(["getActivationTypes", "sendActivation"]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const type = this.activationTypes.find(
            value => value.title === this.ruleForm.type
          ).id;
          const data = {
            username: this.ruleForm.email,
            password: this.ruleForm.password,
            account_type_id: type,
            usdt_wallet: this.ruleForm.wallet,
            referral:
              this.ruleForm.referral && this.ruleForm.referral.length > 1
                ? this.ruleForm.referral.match(/(\d+)/)[0]
                : undefined
          }; // https://fridn.com/sign-up?refLink=4866647692821942
          this.sendActivation(data)
            .then(res => {
              this.activationWallet = res.wallet;
              this.activeStep = "second";
            })
            .catch(err => {
              console.log(err);
              this.$notify.error({
                title: this.$t("ERROR"),
                message: this.$t(err.data.code)
              });
            });
        } else {
          // console.log("error");
          this.$notify.error({
            title: "Validation fields error"
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeSelector(item) {
      const amount = this.activationTypes.find(value => value.title === item);
      this.ruleForm.amount = amount.price > 0 ? amount.price : this.$t("free");
      this.ruleForm.hideWallet = amount.price > 0 ? false : true;
    },
    discard() {
      this.resetForm("ruleForm");
      this.$router.back();
    },
    goSite() {
      window.location = "https://fridn.com";
    },
    goHome() {
      if (this.readAndUnderstand) {
        this.activeStep = "first";
        // this.$router.push("/");
        window.location = "https://fridn.com";
      } else {
        this.dialog = true;
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.activationWallet);
    },
    copyAndHome() {
      this.copy();
      this.$router.push("/");
    }
  },
  created() {
    this.getActivationTypes().catch(err => {
      // console.log(err)
      this.$notify.error({
        title: err.status,
        message: err.statusText
      });  // 0xdac17f958d2ee523a2206206994597c13d831ec7
    });
  }
};
</script>

<style>
.tab {
  height: 1px;
  background-color: #cfd2d3;
}
.active {
  background-color: #3f5193;
  border-radius: 200px 200px 0px 0px;
  height: 5px;
  border: none;
}
.home {
  margin-bottom: 234px;
}

.chevron {
  margin-bottom: 80px;
}

.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions span:before {
  content: url("../assets/button_submit_arrow.svg");
  margin-right: 10px;
}
.instructions span {
  text-align: start;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-left: 24px;
  margin-bottom: 20px;
}
.readme-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  margin-left: 24px;
}
.step {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: rgba(37, 37, 66, 0.5);
  margin-bottom: 30px;
}
.active-step {
  color: #252542;
  margin-bottom: 26px;
}
.row-copy {
  display: flex;
  align-items: center;
  margin-top: 104px;
}
.activation-complete {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 800;
}

</style>
