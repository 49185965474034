<template>
  <div>
    <div class="main-title">{{ $t('busdPageTitle') }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <el-row class="page" id="fix">
      <template v-if="!howItWorksRefill && !howItWorksWithdraw">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item :label="$t('exchangeType')" prop="type" required>
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('exchangeType')"
              size="large"
            >
              <el-option
                :label="$t('exchangeTypeInBUSD')"
                value="usdt_in"
              ></el-option>
              <el-option
                :label="$t('exchangeTypeOutBUSD')"
                value="usdt_out"
              ></el-option>
            </el-select>
          </el-form-item>

          <template v-if="ruleForm.type === 'usdt_out'">
            <div style="border: 1px #DCDFE6 solid; padding: 10px; margin-bottom: 15px; border-radius: 6px">
              <div style="white-space: pre-line; color: #606060">{{$t('busdDescriptionOut')}}</div>
            </div>
            <el-form-item :label="$t('busdWallet')" prop="wallet" required>
              <el-input v-model="ruleForm.wallet"></el-input>
            </el-form-item>
            <el-form-item :label="$t('busdAmount')" prop="amount">
              <el-input
                class="wallet-amount"
                v-model="ruleForm.amount"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('exchangeEmail')" prop="email" required>
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('exchangePassword')"
              prop="password"
              required
              class="pass-input"
            >
              <el-input v-model="ruleForm.password" show-password> </el-input>
            </el-form-item>
          </template>
          <template v-else>
            <div style="border: 1px #DCDFE6 solid; padding: 10px; margin-bottom: 15px; border-radius: 6px">
              <div style="white-space: pre-line; color: #606060">{{$t('busdDescriptionIn')}}</div>
            </div>

            <div class="row-copy-busd">
              <InputComponent
                placeholder="BUSD wallet for transfer"
                readonly
                v-model="walletToCopy"
              />
              <ButtonComponent
                style="margin-left: 20px"
                :content="$t('btnCopy')"
                fill
                @click="walletCopy"
                icon
              />
            </div>
            <el-form-item :label="$t('exchangeEmail')" prop="email" required>
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('exchangeTransaction')" prop="transaction" required>
              <el-input v-model="ruleForm.transaction"></el-input>
            </el-form-item>
          </template>
          <el-form-item prop="terms" required>
            <el-checkbox v-model="ruleForm.terms" name="terms">{{
              $t("termsConditions")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <ButtonComponent :content="$t('btnDiscard')" @click="discard" />
            <ButtonComponent
              :content="$t('btnExchange')"
              fill
              style="margin-left: 20px"
              icon
              @click="submitForm('ruleForm')"
            />
          </el-form-item>
        </el-form>
      </template>
      <template v-if="howItWorksRefill">
        <div class="how-it-works">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>

          <div class="instructions" style="margin-bottom: 40px" >
            <span>{{ $t("busdRefillLine1") }}</span>
            <span>{{ $t("busdRefillLine2") }}</span>
            <span>{{ $t("busdRefillLine3") }}</span>

          </div>

          <ButtonComponent
            :content="$t('btnGoHome')"
            @click="goHome"
            fill
            icon
          />
        </div>
      </template>
      <template v-if="howItWorksWithdraw">
        <div class="how-it-works">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>
          <div class="instructions" style="margin-bottom: 40px">
            <span>{{ $t("busdWithdrawLine1") }}</span>
            <span>{{ $t("busdWithdrawLine2") }}</span>

          </div>

          <ButtonComponent
            :content="$t('btnGoHome')"
            @click="goHome"
            fill
            icon
          />
        </div>
      </template>
    </el-row>
    <el-dialog :visible.sync="dialog" :title="$t('ExchangeDialogTitle')">
      <div class="dialog-refill">
        <span>{{ $t("ExchangeDialogText") }}</span>
        <span
          >{{ $t("ExchangeDialogCommission") }}
          {{ dialogData.commission }} $</span
        >
        <span>{{ $t("ExchangeDialogSum") }} {{ dialogData.sum }} USDT</span>
        <span>{{ $t("ExchangeDialogDescription") }}</span>
      </div>

      <div class="pop-button"  >
        <ButtonComponent
          :content="$t('btnDiscard')"
          @click="() => (this.dialog = false)"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnContinue')"
          icon
          fill
          @click="withdrawal"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent";
import { mapActions } from "vuex";
import InputComponent from "@/components/InputComponent";
export default {
  name: "ExchangeUsdt",
  components: {
    InputComponent,
    ButtonComponent
  },
  data() {
    return {
      checked: false,
      howItWorksRefill: false,
      howItWorksWithdraw: false,
      howItWorksWallet: process.env.VUE_APP_WALLET,
      walletToCopy: null,
      dialog: false,
      dialogData: {
        commission: null,
        sum: null
      },
      howItWorkCopy: false,
      ruleForm: {
        email: "",
        password: "",
        type: "usdt_in",
        amount: "",
        wallet: "",
        referral: "",
        terms: null,
        transaction: ""
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("validatorEmailEmpty"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("validatorEmailValid"),
            trigger: "blue"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("validatorPasswordEmpty"),
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: this.$t("validatorExchangeEmpty"),
            trigger: "blur"
          }
        ],
        wallet: [
          {
            required: true,
            message: this.$t("validatorWalletEmpty"),
            trigger: "blur"
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t("validatorAmountEmpty"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions([
      "getExchangePrice",
      "exchangeUsdtToFaUnit",
      "exchangeFaUnittoUsdt",
      "getBusdWallet",
      "exchangeBusdIn",
      "exchangeBusdOut"
    ]),
    submitForm(formName) {
      console.log('form click', formName, this.ruleForm.type)
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.type === "usdt_out") {
            const data = {
              username: this.ruleForm.email,
              password: this.ruleForm.password,
              wallet: this.ruleForm.wallet,
              amount: this.ruleForm.amount
            };
            this.exchangeBusdOut(data)
              .then(() => {
                this.howItWorksWithdraw = true
              })
              .catch(this.catchError);
          } else {
            const data = {
              email: this.ruleForm.email,
              tx_id: this.ruleForm.transaction,
              tx_type: "EXCHANGE"
            };
            this.exchangeBusdIn(data)
              .then(() => {
                this.howItWorksRefill = true;
              })
              .catch(this.catchError);
          }
        } else {
          // console.log("error");
          return false;
        }
      });
    },
    catchError (err) {
      const message =
        this.$t(err.data.code) === err.data.code
          ? this.$t("other")
          : this.$t(err.data.code, {
            limit: err.data.payload && err.data.payload.limit && err.data.payload.limit,
            available: err.data.payload && err.data.payload.available && err.data.payload.available
          })
      this.$notify.error({
        title: this.$t("ERROR"),
        message: message
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    discard() {
      this.resetForm("ruleForm");
      this.$router.back();
    },
    goHome() {
      this.howItWorksRefill = false;
      // this.$router.push("/");
      window.location = "https://fridn.com"
    },
    async copy() {
      await navigator.clipboard.writeText(this.walletToCopy);
    },
    walletCopy() {
      this.copy()
      //this.howItWorkCopy = true;
    },
    withdrawal() {
      this.exchangeFaUnittoUsdt(this.ruleForm)
        .then(() => {
          this.dialog = false;
          this.howItWorksWithdraw = true;
        })
        .catch(err => {
          this.$notify.error({
            title: this.$t("ERROR"),
            message: this.$t(err.data.code)
          });
        });
    }
  },
  mounted () {
    this.getBusdWallet()
    .then(res => {
      console.log('res', res)
      this.walletToCopy = res.wallet

    })
  }
};
</script>

<style scoped>

.chevron {
  margin-bottom: 80px;
}

.dialog-refill {
  display: flex;
  align-items: start;
  text-align: start;
  flex-direction: column;
}
.dialog-refill span {
  margin-bottom: 10px;
}
.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions span:before {
  content: url("../assets/button_submit_arrow.svg");
  margin-right: 10px;
}
.instructions span {
  text-align: start;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-left: 24px;
  margin-bottom: 20px;
}
.readme-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  margin-left: 24px;
}
.readme-block span {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme-footer {
  margin-top: 20px;
}
.row-copy-ex {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.row-copy-busd {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
