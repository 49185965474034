<template>
  <el-col align="start">
    <div v-if="label">{{label}}<span v-if="required" class="requered">*</span></div>
    <el-input :placeholder="placeholder" :readonly="readonly" v-model="value" >
      <el-button v-if="copy"  slot="suffix" @click="handleClick" type="warning" round >Copy</el-button>
    </el-input>
  </el-col>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }

  },
  methods: {
    handleClick(evt) {
      this.$emit('clickCopy', evt);
    }
  }
};
</script>

<style>
.requered {
  color: red;
  margin-left: 5px;
}
.el-input__inner {
  border-radius: 8px;
  height: 52px;
  margin-top: 5px;
}
</style>
