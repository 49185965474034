<template>
  <button :class="[
    'fr-button',
    fill ? 'fill-style' : '',
    disabled ? 'disabled-style': ''
    ]"
    @click="handleClick"
    type="button"
    :disabled="disabled"
    autofocus
  >{{content}}<div class="btn-icon" v-if="icon"><img :src="require('@/assets/button-arrow.svg')" height="35" width="35" /></div></button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    content: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }

};
</script>

<style scoped>
.btn-icon {
  position: absolute;
  right: 6px;
  top: 6px;

}

.fr-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 2px solid #D8AD4C;
  border-radius: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #252542;
  background-color: white;
  padding-left: 54px;
  padding-right: 54px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: .1s;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

}
.fr-button:hover {
  color: #D8AD4C;
  border-color: #D8AD4C;
  background-color: #FFFFFF;
}
.fill-style {
  color: #fff;
  background-color: #D8AD4C;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-width: 600px) {
  .fill-style {
    font-size: 14px;
  }
  .disabled-style {
    font-size: 14px;
  }
  .fr-button {
    font-size: 14px;
    padding-left: 47px;
    padding-right: 47px;
  }
}

.disabled-style {
  color: #b8b6b6;
  background-color: #777676;
  font-size: 18px;
}
.disabled-style:focus,
.disabled-style:hover {
  background-color: #777676;
}
.el-button::-moz-focus-inner {
  border: 0
}
</style>
