<template>
  <div>
    <div class="main-title">{{ $t('exchangeTitle') }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <el-row class="page" id="fix">
      <template v-if="!howItWorksRefill && !howItWorksWithdraw">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item :label="$t('exchangeEmail')" prop="email" required>
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('exchangePassword')"
            prop="password"
            required
            class="pass-input"
          >
            <el-input v-model="ruleForm.password" show-password> </el-input>
          </el-form-item>
          <el-form-item :label="$t('exchangeType')" prop="type" required>
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('exchangeType')"
              size="large"
            >
              <el-option
                :label="$t('exchangeTypeIn')"
                value="usdt_in"
              ></el-option>
              <el-option
                :label="$t('exchangeTypeOut')"
                value="usdt_out"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('exchangeWallet')" prop="wallet" required>
            <el-input v-model="ruleForm.wallet"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('usdtAmountFees')"
            prop="amount"
            required
            v-if="ruleForm.type === 'usdt_out'"
          >
            <el-input v-model="ruleForm.amount"></el-input>
          </el-form-item>
          <el-form-item prop="terms" required>
            <el-checkbox v-model="ruleForm.terms" name="terms">{{
              $t("termsConditions")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <ButtonComponent :content="$t('btnDiscard')" @click="discard" />
            <ButtonComponent
              :content="$t('btnExchange')"
              fill
              style="margin-left: 20px"
              icon
              @click="submitForm('ruleForm')"
            />
          </el-form-item>
        </el-form>
      </template>
      <template v-if="howItWorksRefill">
        <div class="how-it-works">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>
          <span>{{ $t('faqLabel') }}</span>
          <div class="row-copy-ex">
            <InputComponent

              v-model="howItWorksWallet"
              readonly
            />
            <ButtonComponent
              style="margin-left: 20px"
              :content="$t('btnCopy')"
              fill
              @click="walletCopy"
              icon
            />
          </div>
          <div class="instructions" copy>
            <span>{{ $t("faqLine1") }}</span>
            <span>{{ $t("faqLine2") }}</span>
            <span>{{ $t("faqLine3") }}</span>
            <span>{{ $t("faqLine4") }}</span>
            <span>{{ $t("faqLine5") }}</span>
          </div>
          <span class="readme">{{ $t("faqReadme") }}</span>
          <div class="readme-block">
            <span>{{ $t("faqReadmeLine1") }}</span>
            <span>{{ $t("faqReadmeLine2") }}</span>
            <span>{{ $t("faqReadmeLine3") }}</span>
            <span>{{ $t("faqReadmeLine4") }}</span>
          </div>
          <ButtonComponent
            :content="$t('btnGoHome')"
            @click="goHome"
            fill
            icon
            :disabled="!howItWorkCopy"
          />
          <span class="readme-footer">{{ $t("faqFooter") }}</span>
        </div>
      </template>
      <template v-if="howItWorksWithdraw">
        <div class="how-it-works">
          <span class="readme" style="margin-left: 0">{{
            $t("faqTitle")
          }}</span>
          <div class="instructions">
            <span>{{ $t("withdrawLine1") }}</span>
            <span>{{ $t("withdrawLine2") }}</span>
            <span>{{ $t("withdrawLine3") }}</span>
            <span>{{ $t("withdrawLine4") }}</span>
          </div>
          <span class="readme">{{ $t("faqReadme") }}</span>
          <div class="readme-block">
            <span>{{ $t("withdrawReadme") }}</span>
          </div>
          <ButtonComponent
            :content="$t('btnGoHome')"
            @click="goHome"
            fill
            icon
          />
        </div>
      </template>
    </el-row>
    <el-dialog :visible.sync="dialog" :title="$t('ExchangeDialogTitle')">
      <div class="dialog-refill">
        <span>{{ $t("ExchangeDialogText") }}</span>
        <span
          >{{ $t("ExchangeDialogCommission") }}
          {{ dialogData.commission }} $</span
        >
        <span>{{ $t("ExchangeDialogSum") }} {{ dialogData.sum }} USDT</span>
        <span>{{ $t("ExchangeDialogDescription") }}</span>
      </div>

      <div class="pop-button"  >
        <ButtonComponent
          :content="$t('btnDiscard')"
          @click="() => (this.dialog = false)"
        />
        <ButtonComponent
          class="fridn-button"
          :content="$t('btnContinue')"
          icon
          fill
          @click="withdrawal"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent";
import { mapActions } from "vuex";
import InputComponent from "@/components/InputComponent";
export default {
  name: "Exchange",
  components: {
    InputComponent,
    ButtonComponent
  },
  data() {
    return {
      checked: false,
      howItWorksRefill: false,
      howItWorksWithdraw: false,
      howItWorksWallet: process.env.VUE_APP_WALLET,
      dialog: false,
      dialogData: {
        commission: null,
        sum: null
      },
      howItWorkCopy: false,
      ruleForm: {
        email: "",
        password: "",
        type: "",
        amount: "",
        wallet: "",
        referral: "",
        terms: null
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("validatorEmailEmpty"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("validatorEmailValid"),
            trigger: "blue"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("validatorPasswordEmpty"),
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: this.$t("validatorExchangeEmpty"),
            trigger: "blur"
          }
        ],
        wallet: [
          {
            required: true,
            message: this.$t("validatorWalletEmpty"),
            trigger: "blur"
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t("validatorAmountEmpty"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions([
      "getExchangePrice",
      "exchangeUsdtToFaUnit",
      "exchangeFaUnittoUsdt"
    ]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.type === "usdt_out") {
            const data = {
              amount: this.ruleForm.amount
            };
            this.getExchangePrice(data)
              .then(res => {
                this.dialogData.commission = res.fee.toFixed(2);
                this.dialogData.sum = res.usdt.toFixed(2);
                this.dialog = true;
              })
              .catch(err => {
                this.$notify.error({
                  title: this.$t("ERROR"),
                  message: this.$t(err.data.code)
                });
              });
          } else {
            this.exchangeUsdtToFaUnit(this.ruleForm)
              .then(() => {
                this.howItWorksRefill = true;
              })
              .catch(err => {
                this.$notify.error({
                  title: this.$t("ERROR"),
                  message: this.$t(err.data.code)
                });
              });
          }
        } else {
          // console.log("error");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    discard() {
      this.resetForm("ruleForm");
      this.$router.back();
    },
    goHome() {
      this.howItWorksRefill = false;
      // this.$router.push("/");
      window.location = "https://fridn.com"
    },
    async copy() {
      await navigator.clipboard.writeText(this.howItWorksWallet);
    },
    walletCopy() {
      this.copy()
      this.howItWorkCopy = true;
    },
    withdrawal() {
      this.exchangeFaUnittoUsdt(this.ruleForm)
        .then(() => {
          this.dialog = false;
          this.howItWorksWithdraw = true;
        })
        .catch(err => {
          this.$notify.error({
            title: this.$t("ERROR"),
            message: this.$t(err.data.code)
          });
        });
    }
  }
};
</script>

<style scoped>

.chevron {
  margin-bottom: 80px;
}

.dialog-refill {
  display: flex;
  align-items: start;
  text-align: start;
  flex-direction: column;
}
.dialog-refill span {
  margin-bottom: 10px;
}
.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.instructions span:before {
  content: url("../assets/button_submit_arrow.svg");
  margin-right: 10px;
}
.instructions span {
  text-align: start;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-left: 24px;
  margin-bottom: 20px;
}
.readme-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  margin-left: 24px;
}
.readme-block span {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
}
.readme-footer {
  margin-top: 20px;
}
.row-copy-ex {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
</style>
