<template>
  <div>
    <div class="main-title">{{ $t('accountActivation') }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <el-row class="page" id="fix">
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <div style="border: 1px #DCDFE6 solid; padding: 10px; margin-bottom: 15px; border-radius: 6px">
<!--          <div style="white-space: pre-line; color: #606060">{{$t('busdDescriptionIn')}}</div>-->
          <div style="color: #606060; margin-bottom: 10px">{{$t('activationDescLine1')}}</div>
          <div style="color: #606060; margin-bottom: 10px">{{$t('activationDescLine2')}}</div>
          <div style="color: #606060">{{$t('activationDescLine3')}}</div>
          <div style="color: #606060">{{$t('activationDescLine4')}}</div>
          <div style="color: #606060">{{$t('activationDescLine5')}}</div>
          <div style="color: #606060; margin-top: 10px">{{$t('activationDescLine6')}}</div>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 15px">
          <InputComponent
            placeholder="BUSD wallet for transfer"
            readonly
            v-model="walletToCopy"
          />
          <ButtonComponent
            style="margin-left: 20px"
            :content="$t('btnCopy')"
            fill
            @click="walletCopy"
            icon
          />
        </div>
        <el-form-item :label="$t('exchangeEmail')" prop="email" required>
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('exchangePassword')"
          prop="password"
          required
          class="pass-input"
        >
          <el-input v-model="ruleForm.password" show-password> </el-input>
        </el-form-item>
        <el-form-item :label="$t('exchangeTransaction')" prop="transaction" required>
          <el-input v-model="ruleForm.transaction"></el-input>
        </el-form-item>
        <el-alert
          type="info"
          show-icon
          style="margin-bottom: 15px; padding: 10px 10px 20px 10px"
        >
          <template v-slot:default>
            <div style="display: flex; flex-direction: column; align-items: start">
              <span class="info-title">{{$t('instTitle1')}}</span>
              <ul style="text-align: start">
                <li v-html="$t('instLine1')"></li>
                <li v-html="$t('instLine2')"></li>
                <li v-html="$t('instLine3')"></li>
                <li v-html="$t('instLine4')"></li>
                <li v-html="$t('instLine5')"></li>
              </ul>
              <span class="info-title">{{$t('instTitle2')}}</span>
              <ul style="text-align: start">
                <li v-html="$t('instLine6')"></li>
                <li v-html="$t('instLine7')"></li>
                <li v-html="$t('instLine8')"></li>
              </ul>
            </div>
          </template>
        </el-alert>
        <el-form-item prop="terms" required>
          <el-checkbox v-model="ruleForm.terms" name="terms">{{
              $t("termsConditions")
            }}</el-checkbox>
        </el-form-item>
        <el-form-item>
          <ButtonComponent :content="$t('btnDiscard')" @click="discard" />
          <ButtonComponent
            :content="$t('btnActivate')"
            fill
            style="margin-left: 20px"
            icon
            @click="submitForm('ruleForm')"
          />
        </el-form-item>
      </el-form>
    </el-row>
    <el-dialog :visible.sync="resultDialog">
        <div style="margin-top: 40px; margin-bottom: 40px">
          <el-row >
            <span style="font-size: 20px; font-weight: 400">{{ $t("activationConfirm") }}</span>
          </el-row>
          <ButtonComponent :content="$t('btnOk')" @click="() => this.resultDialog = false" style="margin-top: 20px" />
        </div>
    </el-dialog>
  </div>
</template>
<script>
import ButtonComponent from "@/components/ButtonComponent";
import InputComponent from "@/components/InputComponent";
import { mapActions } from "vuex"

export default {
  name: "ActivationBusd",
  components: {
    ButtonComponent,
    InputComponent
  },
  data() {
    return {
      resultDialog: false,
      walletToCopy: null,
      ruleForm: {
        email: "",
        password: "",
        terms: null,
        transaction: ""
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("validatorEmailEmpty"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("validatorEmailValid"),
            trigger: "blue"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("validatorPasswordEmpty"),
            trigger: "blur"
          }
        ],
        transaction: [
          {
            required: true,
            message: this.$t("validatorExchangeEmpty"),
            trigger: "blur"
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      "getBusdWallet",
      "sendActivationBusd"
    ]),
    submitForm (formName) {
      console.log(formName)
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            username: this.ruleForm.email,
            password: this.ruleForm.password,
            tx_id: this.ruleForm.transaction
          }
          this.sendActivationBusd(data)
            .then(() => {
              // что то нужно сделать
              //window.location = "https://fridn.com";
              this.resetForm(formName)
              this.resultDialog = true
            })
            .catch(err => {
              const message =
                this.$t(err.data.code) === err.data.code
                  ? this.$t("other")
                  : this.$t(err.data.code)
              this.$notify.error({
                title: this.$t("ERROR"),
                message: message
              })
            })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    discard() {
      this.resetForm("ruleForm");
      this.$router.back();
    },
    async copy() {
      await navigator.clipboard.writeText(this.walletToCopy);
    },
    walletCopy() {
      this.copy()
    },
  },
  mounted () {
    this.getBusdWallet()
      .then(res => {
        console.log('res', res)
        this.walletToCopy = res.wallet

      })
  }
}
</script>
<style lang="scss">
.info-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

</style>
